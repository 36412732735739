import React, { useState } from "react";
import { useRouter } from "next/router";

import ProfessorBanner from "./Professor-Banner";
import ProfessorFilter from "./Filter/ProfessorFilter";

const ProfessorHead = ({ category, filterItem, setCourseFilter }) => {
  const router = useRouter();
  const path = router.pathname;
  const [filterToggle, setFilterToggle] = useState(false);
  const [activeTab, setActiveTab] = useState("All Course");

  const handleButtonClick = (courseType) => {
    setCourseFilter(category);
    setActiveTab(courseType);
    filterItem(courseType);
  };

  return (
    <>
      <div className="rbt-page-banner-wrapper">
        <div className="rbt-banner-image"></div>

        <div className="rbt-banner-content">
          <ProfessorBanner />

          <div className="rbt-course-top-wrapper mt--40 mt_sm--20">
            <div className="container">
              <div className="row g-5 align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="rbt-sorting-list d-flex flex-wrap align-items-end justify-content-start justify-content-lg-end">
                    {path === "/course-with-tab" ||
                    path === "/course-with-tab-two" ||
                    path === "/course-with-sidebar" ? (
                      <div className="rbt-short-item">
                        <div className="filter-select">
                          <span className="select-label d-block">Short By</span>
                          <div className="filter-select rbt-modern-select search-by-category">
                            <select data-size="7">
                              <option>Default</option>
                              <option>Latest</option>
                              <option>Popularity</option>
                              <option>Trending</option>
                              <option>Price: low to high</option>
                              <option>Price: high to low</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {path === "/course-filter-two-open" ||
                    path === "/course-filter-two-toggle" ||
                    path === "/course-filter-one-toggle" ||
                    path === "/course-card-2" ||
                    path === "/course-card-3" ||
                    path === "/course-masonry" ? (
                      <div className="rbt-short-item">
                        <div className="view-more-btn text-start text-sm-end">
                          <button
                            className="discover-filter-button discover-filter-activation rbt-btn btn-white btn-md radius-round"
                            onClick={() => setFilterToggle(!filterToggle)}
                          >
                            Filter<i className="feather-filter"></i>
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <ProfessorFilter filterToggle={filterToggle} />

                {path === "/course-with-tab" ||
                path === "/course-with-tab-two" ||
                path === "/course-masonry" ? (
                  <div className="col-lg-12 mt--60">
                    <ul
                      className="rbt-portfolio-filter filter-tab-button justify-content-start nav nav-tabs"
                      id="rbt-myTab"
                      role="tablist"
                    >
                      {[
                        "All Course",
                        "featured",
                        "popular",
                        "trending",
                        "latest",
                      ].map((courseType, index) => (
                        <li
                          key={index}
                          className="nav-item"
                          role="presentation"
                        >
                          <button
                            className={activeTab === courseType ? "active" : ""}
                            type="button"
                            onClick={() => handleButtonClick(courseType)}
                          >
                            <span className="filter-text">{courseType}</span>
                            {courseType === "All Course" ? (
                              <span className="course-number">
                                {category.filter((course) => course).length}
                              </span>
                            ) : (
                              <span className="course-number">
                                0
                                {
                                  category.filter(
                                    (course) => course.courseType === courseType
                                  ).length
                                }
                              </span>
                            )}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessorHead;
