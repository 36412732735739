const ProfessorBanner = () => {
  return (
    <>
      <div className="rbt-banner-content-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className=" title-wrapper">
                <h1 className="title mb--0">
                  Os melhores professores para você!
                </h1>
              </div>
              <p className="description">
                <small>
                  Procurando uma ótima maneira de aprender mais? O Minha
                  Educação fornece a você professores qualificados. <br />
                  Contrate um tutor online para ajudá-lo a aprender.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessorBanner;
